import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import _ from 'lodash';
import classnames from 'classnames';
import ReactDatetime from 'react-datetime';
import Select from 'react-select';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Form,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from 'reactstrap';
import store from 'store2';
import { StarIcon as SolidStarIcon } from '@heroicons/react/24/solid';
import ReactTable from 'components/ReactTable/ReactTable';
import PageTabs from 'components/PageTabs';
import {
  listProducts,
  getDiscovery,
  getCharacteristicsDiscovery,
  getRiskPerformanceDiscovery,
  getInvestmentAnalysisWithCustomData,
} from 'services/analysis';
import { listWatchlists } from 'services/watchlist';
import { optionizeValue, optionizeAll, getValue, getDate, getYearMonth, monthDiff } from 'helpers/select';
import { confidenceLevelToInterval, toDeciTable, tablePresets } from 'helpers/formatter';
import AssetUpload from './AssetUpload';
import { fetchData as iaFetchData } from './investment-analysis/data';
import { fetchData as icFetchData } from './investment-comparison/data';
import { tableState } from 'components/ReactTable/ReactTable';

const SpinnerWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const CUSTOM_DATA = 'custom-data';

const addOrRemove = (selections, database, asset) => {
  const index = _.findIndex(selections, (v) => v.database === database && v.asset === asset);

  if (index === -1) {
    return selections.concat({ database, asset });
  } else {
    return selections.filter((_, i) => i !== index);
  }
};

const nameHeader = {
  Header: 'Name',
  accessor: 'Name',
  disableSortBy: false,
  disableFilters: false,
  Cell: ({ value: initialValue, row, column }) => {
    const isBookmarked = row.original.__watchlistIndex > -1;

    return (
      <>
        <span>{initialValue}</span>
        {isBookmarked && <SolidStarIcon className="tw-h-6 tw-w-6 tw-text-amber-300" aria-hidden="true" />}
      </>
    );
  },
};

const ColumnCell = ({ value: initialValue, row, column }) => {
  if (isNaN(Number(initialValue))) {
    return initialValue;
  } else {
    let colname = column.id.toLowerCase();
    if (colname.endsWith('beta')) colname = 'beta';
    else if (colname.endsWith('cagr')) colname = 'cagr';
    else if (colname.endsWith('std dev')) colname = 'std dev';

    const val = tablePresets[colname] ? tablePresets[colname](initialValue) : tablePresets['percentage'](initialValue);

    if (_.isObject(val)) {
      const { className = '', style = {}, value = '' } = val;

      return (
        <span className={classnames(className)} style={style}>
          {value}
        </span>
      );
    }

    return val;
  }
};

const sortMethod = (rowA, rowB, id, desc) => {
  const a = rowA.values[id];
  const b = rowB.values[id];

  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  } else {
    const isnuma = typeof a === 'number';
    const isnumb = typeof b === 'number';

    if (isnuma && isnumb) {
      return a - b;
    }

    if (isnuma) {
      return desc ? 1 : -1;
    } else {
      return desc ? -1 : 1;
    }
  }
};

const getHeaders = (columns, assets, selectedTab1, selectedTab2) => {
  if (!columns) return [];
  // use below when using regular table not flex table
  //
  // if (_.isArray(columns[0])) {
  //   const otherHeaders = [];

  //   _.each(columns, (col, index) => {
  //     const existing = otherHeaders.find((v) => v.Header === col[0]);
  //     if (existing) {
  //       existing.columns.push({
  //         Header: col[1],
  //         accessor: `${col[0]}-${index}`,
  //         disableSortBy: false,
  //         disableFilters: false,
  //       });
  //     } else {
  //       otherHeaders.push({
  //         Header: col[0],
  //         columns: [
  //           {
  //             Header: col[1],
  //             accessor: `${col[0]}-${index}`,
  //             disableSortBy: false,
  //             disableFilters: false,
  //           },
  //         ],
  //       });
  //     }
  //   });

  //   return [nameHeader, ...otherHeaders];
  // }

  if (_.isArray(columns[0])) {
    return [
      nameHeader,
      ...columns.map((col, index) => {
        let disableFilters = false;
        if (selectedTab1 === 'risk-performance') {
          disableFilters = !['Name', 'Database', 'Type'].includes(col);
        }

        const accessor = ['Database'].includes(col[1]) ? col[1] : `${col[0]}-${col[1]}`;
        // const isNumericHeader = assets.length > 0 ? !isNaN(Number(assets[0][accessor])) : false;
        return {
          Header: col[1] || col[0],
          accessor,
          disableSortBy: false,
          disableFilters,
          Cell: ColumnCell,
          sortType: sortMethod,
        };
      }),
    ];
  }

  return [
    nameHeader,
    ...columns.map((col, index) => {
      let disableFilters = false;
      if (selectedTab1 === 'risk-performance') {
        disableFilters = !['Name', 'Database', 'Type'].includes(col);
      }

      const key = _.isEmpty(col) ? `no key - ${index}` : col;
      // const isNumericHeader = assets.length > 0 ? !isNaN(Number(assets[0][col])) : false;
      return {
        Header: key,
        accessor: key,
        disableSortBy: false,
        disableFilters,
        Cell: ColumnCell,
        sortType: sortMethod,
      };
    }),
    // {
    //   Header: 'Actions',
    //   accessor: 'actions',
    //   sortable: false,
    //   filterable: false,
    // },
  ];
};

const getParentHeaders = (columns) => {
  if (!columns || !_.isArray(columns[0])) return;

  const headers = [' '];

  let currValue;
  _.each(columns, (col, index) => {
    const value = col[0];
    if (!col[1]) {
      headers.push(' ');
    } else if (!currValue) {
      headers.push(value);
      currValue = value;
    } else if (currValue === value) {
      headers.push(' ');
    } else {
      headers.push(value);
      currValue = value;
    }
  });

  return headers;
};

// const getParentHeaders = (columns) => {
//   if (!_.isArray(columns[0])) return;

//   const headers = [{ key: `indexname`, value: '', span: 1 }];

//   let currValue;
//   let currCount;
//   _.each(columns, (col, index) => {
//     const value = col[0];
//     if (currValue === value) {
//       currCount += 1;
//     } else {
//       if (currValue) headers.push({ key: `${value}-${index}`, value: currValue, span: currCount });
//       currValue = value;
//       currCount = 1;
//     }
//   });

//   return headers;
// };

const processData = (data, cols, ind, watchlists) => {
  if (!cols) return [];

  const databaseIndex = _.isArray(cols[0])
    ? cols.findIndex((v) => v[1] === 'Database')
    : cols.findIndex((v) => v === 'Database');

  const result = data.map((row, i) => {
    const entry = {
      id: i,
      Name: ind[i],
      __watchlistIndex: watchlists.findIndex((w) => w.asset === ind[i] && w.database === row[databaseIndex]),
    };

    _.each(row.slice(0, 100), (v, j) => {
      let key;
      if (_.isArray(cols[j])) {
        key = ['Database'].includes(cols[j][1]) ? cols[j][1] : `${cols[j][0]}-${cols[j][1]}`;
      } else {
        key = cols[j];
      }

      entry[key] = v;
    });

    return entry;
  });

  return _.orderBy(result, ['__watchlistIndex'], ['desc']);
};

const tabOptions1 = [
  { value: 'characteristics', label: 'Characteristics' },
  { value: 'risk-performance', label: 'Risk & Performance' },
];

const tabOptions2 = [
  { value: 'Calendar Year Returns', label: 'Calendar Year Returns' },
  { value: 'Short-Term Returns', label: 'Short-Term Returns' },
  { value: 'Long-Term Returns', label: 'Long-Term Returns' },
  { value: 'Risk Metrics', label: 'Risk Metrics' },
  // { value: 'Advanced Risk Metrics', label: 'Advanced Risk Metrics' },
];

function AssetDiscovery({ session }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [multi, setMulti] = useState(false);
  const [minass, setMinass] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [metaLoading, setMetaLoading] = useState(false);
  const [watchlists, setWatchlists] = useState([]);
  const [databaseOptions, setDatabaseOptions] = useState([]);
  const [selectedDatabaseOptions, setSelectedDatabaseOptions] = useState([]);
  const [durationOptions, setDurationOptions] = useState([]);
  const [selectedDurationOption, setSelectedDurationOption] = useState();
  const [selectedTab1, setSelectedTab1] = useState(tabOptions1[0]);
  const [selectedTab2, setSelectedTab2] = useState(tabOptions2[0]);
  const [characteristicsDiscovery, setCharacteristicsDiscovery] = useState();
  const [riskPerformanceDiscovery, setRiskPerformanceDiscovery] = useState();
  const [assets, setAssets] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [parentHeaders, setParentHeaders] = useState([]);
  const [selections, setSelections] = useState([]);
  const [benchmarks, setBenchmarks] = useState([]);
  const [benchmark, setBenchmark] = useState(null);
  const [geographys, setGeographys] = useState([]);
  const [geography, setGeography] = useState(null);
  const [riskbuckets, setRiskbuckets] = useState([]);
  const [riskbucket, setRiskbucket] = useState(null);
  const [correlations, setCorrelations] = useState([]);
  const [correlation, setCorrelation] = useState(null);
  const [significances, setSignificances] = useState([]);
  const [significance, setSignificance] = useState(null);
  const [uploadedData, setUploadedData] = useState(null);
  const [sdate, setSdate] = useState(null);
  const [edate, setEdate] = useState(null);
  const [error, setError] = useState('');
  const maxItems = session?.permissions['ic-max-items'] ?? 1;

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    setMulti(search.get('multi') === 'true');
    setMinass(search.get('minass') === 'true');
    setSelections([]);
    tableState.filters = {};
  }, [location]);

  const fetchData = async () => {
    setDataLoading(true);

    const [characteristicsDiscovery, riskPerformanceDiscovery, watchlistsResult] = await Promise.all([
      getCharacteristicsDiscovery(false),
      getRiskPerformanceDiscovery(),
      listWatchlists(),
    ]);

    setCharacteristicsDiscovery(characteristicsDiscovery[0]);
    setRiskPerformanceDiscovery(riskPerformanceDiscovery[0]);
    setWatchlists(watchlistsResult[0]);
    setDataLoading(false);
  };

  const fetchMeta = async () => {
    setMetaLoading(true);
    const [meta, err] = await getDiscovery();
    if (!meta) {
      setBenchmarks([]);
      setBenchmark(null);
      setGeographys([]);
      setGeography(null);
      setRiskbuckets([]);
      setRiskbucket(null);
      setCorrelations([]);
      setCorrelation(null);
      setSignificances([]);
      setSignificance(null);
    } else {
      const benchmarks = meta.benchmark_choice.map(optionizeValue);
      const geographys = meta.geography_choice.map(optionizeValue);
      const riskbuckets = meta.risk_budget_choice.map(optionizeValue);
      const correlations = meta.correlation_choice.map(optionizeValue);
      const significances = meta.significance_choice.map((level) => ({
        value: level,
        label: confidenceLevelToInterval(level),
      }));

      setBenchmarks(benchmarks);
      setBenchmark(benchmarks[0]);
      setGeographys(geographys);
      setGeography(geographys[0]);
      setRiskbuckets(riskbuckets);
      setRiskbucket(riskbuckets[0]);
      setCorrelations(correlations);
      setCorrelation(correlations[0]);
      setSignificances(significances);
      setSignificance(significances[0]);
    }

    setMetaLoading(false);
  };

  useEffect(() => {
    fetchData();
    fetchMeta();
  }, []);

  useEffect(() => {
    let data;
    if (selectedTab1.value === 'characteristics') {
      data = characteristicsDiscovery;
    } else if (riskPerformanceDiscovery) {
      data = riskPerformanceDiscovery[selectedTab2.value];
    }

    if (!data) return;

    const databaseKeys = _.keys(data);
    const databaseOptions = optionizeAll(databaseKeys);
    setDatabaseOptions(databaseOptions);

    if (selectedTab1.value === 'risk-performance' && selectedTab2.value === 'Advanced Risk Metrics') {
      const dateKeys = _.keys(data[databaseKeys[0]]);
      const dateOptions = optionizeAll(dateKeys);
      setDurationOptions(dateOptions);
      setSelectedDurationOption(dateOptions[0]);
    }
  }, [selectedTab1, selectedTab2, characteristicsDiscovery, riskPerformanceDiscovery]);

  useEffect(() => {
    if (databaseOptions.length === 0) return;

    let data;
    if (selectedTab1.value === 'characteristics') {
      data = characteristicsDiscovery;
    } else {
      data = riskPerformanceDiscovery[selectedTab2.value];
    }

    if (!data) return;

    let columns = [];
    const index = [];
    const rows = [];

    if (selectedTab1.value === 'risk-performance' && selectedTab2.value === 'Advanced Risk Metrics') {
      columns = data[databaseOptions[0].value][selectedDurationOption.value].columns;

      _.each(selectedDatabaseOptions, (option) => {
        const target = data[option.value][selectedDurationOption.value];
        if (target.index) {
          index.push(...target.index);
          rows.push(...target.data);
        }
      });
    } else {
      columns = data[databaseOptions[0].value].columns;

      _.each(selectedDatabaseOptions, (option) => {
        index.push(...data[option.value].index);
        rows.push(...data[option.value].data);
      });
    }

    const processed = processData(rows, columns, index, watchlists);
    setAssets(processed);
    setHeaders(getHeaders(columns, processed, selectedTab1.value, selectedTab2.value));
    setParentHeaders(getParentHeaders(columns));
  }, [databaseOptions, selectedDatabaseOptions, durationOptions, selectedDurationOption, watchlists]);

  const handleRowSelect = useCallback(
    (row, rows) => {
      if (multi) {
        const newSelections = addOrRemove(selections, row.original.Database, row.original.Name);
        if (newSelections.length > maxItems) return;

        setSelections(newSelections);
      } else {
        setSelections([{ database: row.original.Database, asset: row.original.Name }]);
      }
    },
    [multi, selections, selectedDatabaseOptions],
  );

  const allowed = useMemo(() => {
    if (uploadedData) return true;
    if (!selections) return false;
    if (multi) return selections.length > 1;
    return selections.length > 0;
  }, [selections, uploadedData]);

  const tag = location.pathname.split('-').pop();

  const handleAnalyze = useCallback(async () => {
    if (!allowed) return;

    const mdiff = monthDiff(sdate, edate);
    if (mdiff !== Number.MIN_VALUE) {
      if (mdiff < 0) {
        setError('Start date cannot be after end date.');
        return;
      }

      if (mdiff < 11) {
        setError('The analysis time interval should include at least 12 months.');
        return;
      }
    }

    const isCustom = !!uploadedData;

    const params = {
      benchmark: getValue(benchmark),
      geography: getValue(geography),
      riskbucket: getValue(riskbucket),
      correlation: getValue(correlation),
      significance: getValue(significance),
      sdate: getYearMonth(sdate),
      edate: '',
      custom: isCustom,
    };

    if (!isCustom) {
      params.product = _.map(selections, 'asset');
      params.database = _.map(selections, 'database');
    }

    let pathname = '';
    let state = {};
    let error = null;

    switch (tag) {
      case 'ia':
        const iaResult = await iaFetchData(params);
        if (iaResult.success) {
          pathname = '/investment-analysis';
          state = iaResult;
        } else {
          error = iaResult.error;
        }

        break;
      case 'ic':
        const icResult = await icFetchData(params);
        if (icResult.success) {
          pathname = '/investment-comparison';
          state = icResult;
        } else {
          error = icResult.error;
        }

        break;
      case 'aa':
        pathname = '/advanced-analytics';
        break;
    }

    if (error) {
      toast.error(error);
    } else if (pathname) {
      navigate(
        {
          pathname,
          search: `?${createSearchParams(params)}`,
        },
        { replace: false, state },
      );
    }
  }, [allowed, selections, benchmark, geography, riskbucket, correlation, significance, sdate, edate, tag]);

  return (
    <>
      {['ia', 'ic'].includes(tag) && (
        <AssetUpload
          key={tag}
          onUploaded={(uploaded) => {
            setUploadedData(uploaded);
            store.set(CUSTOM_DATA, uploaded);
          }}
          tag={tag}
        />
      )}

      <Card>
        <CardBody>
          {dataLoading ? (
            <SpinnerWrapper>
              <Spinner>Loading...</Spinner>
            </SpinnerWrapper>
          ) : uploadedData ? (
            <div className="tw-text-center">
              <div className="tw-text-2xl">Your asset data has been added successfully.</div>
              <Button
                className="btn-round"
                color="danger"
                onClick={() => {
                  setUploadedData(null);
                  store.clear(CUSTOM_DATA);
                }}
              >
                Remove
              </Button>
            </div>
          ) : (
            <>
              <PageTabs
                options={tabOptions1}
                onTabSelect={(newValue) => {
                  setSelectedTab1(newValue);
                  setSelectedTab2(tabOptions2[0]);
                }}
                session={session}
              >
                <TabPane tabId="risk-performance" role="tabpanel">
                  <PageTabs
                    options={tabOptions2}
                    onTabSelect={setSelectedTab2}
                    colorIndex={1}
                    session={session}
                  ></PageTabs>
                </TabPane>
              </PageTabs>
              <div className="mb-0">
                <div className="tw-text-left tw-font-bold">SELECT DATA</div>
                <div className="tw-flex">
                  <div className="tw-whitespace-nowrap"></div>
                  <Select
                    className="react-select tw-text-sm tw-inline-block tw-w-full"
                    name="selectedDatabaseOptions"
                    value={selectedDatabaseOptions}
                    onChange={(selections) => {
                      const isAll = selections.find((sel) => sel.value === 'Select All');
                      setSelectedDatabaseOptions(isAll ? databaseOptions.concat() : selections);
                    }}
                    placeholder="Select Dataset..."
                    options={[{ label: 'Select All', value: 'Select All' }, ...databaseOptions]}
                    isMulti={true}
                  />
                </div>
                {selectedTab1.value === 'risk-performance' && selectedTab2.value === 'Advanced Risk Metrics' && (
                  <>
                    <div className="tw-text-left tw-font-bold tw-mt-1">SELECT TIME PERIOD</div>
                    <div>
                      <Select
                        className="react-select tw-text-sm tw-inline-block tw-w-[300px]"
                        name="selectedDurationOption"
                        value={selectedDurationOption}
                        onChange={setSelectedDurationOption}
                        options={durationOptions}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="tw-mt-2">
                {selections &&
                  selections.map((v) => (
                    <Badge key={`${v.database}-${v.asset}`} color="success" tag="span" pill>
                      {`${v.database}: ${v.asset}`}
                      {multi && (
                        <span
                          className="tw-ml-2 tw-cursor-pointer hover:tw-text-red-700"
                          onClick={() => {
                            const newSelections = addOrRemove(selections, v.database, v.asset);
                            setSelections(newSelections);
                          }}
                        >
                          X
                        </span>
                      )}
                    </Badge>
                  ))}
              </div>
              {multi && (
                <div className="">
                  <span className="tw-text-md tw-italic tw-text-gray-700">{`${selections.length} selected. (select between 2 to ${maxItems} assets)`}</span>
                </div>
              )}
              {assets.length > 0 && (
                <ReactTable
                  data={assets.map((asset) => {
                    asset.__selected =
                      selections.findIndex((v) => v.database === asset['Database'] && v.asset === asset['Name']) > -1;

                    return asset;
                  })}
                  columns={headers}
                  parentColumns={parentHeaders}
                  onRowSelect={handleRowSelect}
                />
              )}
            </>
          )}
        </CardBody>
      </Card>

      <Card>
        {metaLoading ? (
          <SpinnerWrapper className="mt-3">
            <Spinner>Loading...</Spinner>
          </SpinnerWrapper>
        ) : (
          <>
            <CardBody className="mt-3">
              <Form className="form-horizontal">
                {tag !== 'ia' && (
                  <Row>
                    <Col sm="3" className="text-right">
                      <Label>Benchmark Selection</Label>
                    </Col>
                    <Col sm="7">
                      <FormGroup>
                        {benchmark && (
                          <Select
                            className="react-select"
                            name="benchmark"
                            value={benchmark}
                            onChange={setBenchmark}
                            options={benchmarks}
                          />
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                )}
                {!minass && (
                  <>
                    <Row>
                      <Col sm="3" className="text-right">
                        <Label>Geography (CAPM &amp; FFM)</Label>
                      </Col>
                      <Col sm="7">
                        <FormGroup>
                          {geography && (
                            <Select
                              className="react-select"
                              name="geography"
                              value={geography}
                              onChange={setGeography}
                              options={geographys}
                            />
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="3" className="text-right">
                        <Label>Factor Groups</Label>
                      </Col>
                      <Col sm="7">
                        <FormGroup>
                          {riskbucket && (
                            <Select
                              className="react-select"
                              name="riskbucket"
                              value={riskbucket}
                              onChange={setRiskbucket}
                              options={riskbuckets}
                            />
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="3" className="text-right">
                        <Label>Correlation Group</Label>
                      </Col>
                      <Col sm="7">
                        <FormGroup>
                          {correlation && (
                            <Select
                              className="react-select"
                              name="correlation"
                              value={correlation}
                              onChange={setCorrelation}
                              options={correlations}
                            />
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="3" className="text-right">
                        <Label>Confidence Interval</Label>
                      </Col>
                      <Col sm="7">
                        {significance && (
                          <FormGroup>
                            <Select
                              className="react-select"
                              name="significance"
                              value={significance}
                              onChange={setSignificance}
                              options={significances}
                            />
                          </FormGroup>
                        )}
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <Col sm="3" className="text-right">
                    <Label>Start Date (Optional)</Label>
                  </Col>
                  <Col sm="7">
                    <FormGroup>
                      <ReactDatetime
                        initialValue={sdate}
                        dateFormat="YYYY-MM"
                        onChange={setSdate}
                        inputProps={{
                          className: 'form-control',
                          placeholder: 'Start Date',
                        }}
                        closeOnSelect={true}
                        timeFormat={false}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {/* <Row>
                  <Col sm="3" className="text-right">
                    <Label>End Date (Optional)</Label>
                  </Col>
                  <Col sm="7">
                    <FormGroup>
                      <ReactDatetime
                        initialValue={edate}
                        dateFormat="YYYY-MM"
                        onChange={setEdate}
                        inputProps={{
                          className: 'form-control',
                          placeholder: 'End Date',
                        }}
                        closeOnSelect={true}
                        timeFormat={false}
                      />
                    </FormGroup>
                  </Col>
                </Row> */}
                {error && (
                  <Row>
                    <Col sm="3"></Col>
                    <Col sm="7">
                      <div className="tw-text-[#ef8157]">{error}</div>
                    </Col>
                  </Row>
                )}
              </Form>
            </CardBody>
            <CardFooter className="mt-0">
              <Row>
                <Col md="3" />
                <Col md="9">
                  <Button className="btn-round" color="info" disabled={!allowed} onClick={handleAnalyze}>
                    Analyze
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </>
        )}
      </Card>
    </>
  );
}

export default AssetDiscovery;
