import { useRef, useState, useEffect, createRef, forwardRef, useMemo, memo } from 'react';
import { useTable, useFilters, useRowSelect, useSortBy, usePagination } from 'react-table';
import classnames from 'classnames';
import _ from 'lodash';
import styled from 'styled-components';
import { matchSorter } from 'match-sorter';
import Select from 'react-select';
import { Container, Row, Col, FormGroup, Input } from 'reactstrap';
import { proxy, useSnapshot } from 'valtio';

export const tableState = proxy({ filters: {} });

const TableWrapper = styled.div`
  overflow-x: scroll;
`;

const RtTable = styled.table`
  display: ${(props) => (props.compact ? 'flex' : 'table')} !important;
`;

const RtCell = styled.td`
  white-space: pre-wrap !important;
  line-height: 1.1rem !important;
`;

const ParentHeader = styled.th`
  flex: 1 0;
  text-overflow: unset;
  overflow: visible;
  white-space: pre-wrap;
  text-align: center;
  line-height: 100% !important;
  color: gray;
  font-size: 0.9rem;
`;

const selectedColor = 'rgb(143, 209, 158)';

// Define a default UI for filtering
function DefaultColumnFilter({ column }) {
  const snap = useSnapshot(tableState);
  const { id, filterValue, preFilteredRows, setFilter } = column;
  const count = preFilteredRows.length;

  return (
    <FormGroup>
      <Input
        placeholder={`Search...`}
        type="text"
        value={snap.filters[id] ?? ''}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onChange={(e) => {
          const val = e.target.value || undefined;
          setFilter(val); // Set undefined to remove the filter entirely
          tableState.filters = { ...snap.filters, [id]: val };
        }}
      />
    </FormGroup>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input type="radio" ref={resolvedRef} {...rest} name="tsetsetsetest" />
      <label>{rest.row.values.Name}</label>
    </>
  );
});

function Table({
  id = '',
  columns,
  parentColumns,
  data,
  onRowSelect = _.noop,
  compact = false,
  initialPage = 0,
  navigatePage = _.noop,
  className,
}) {
  const snap = useSnapshot(tableState);
  // const elementsRef = useRef(data.map(() => createRef()));
  const [numberOfRows, setNumberOfRows] = useState({
    value: 5,
    label: '5 rows',
  });

  const [pageSelect, handlePageSelect] = useState({
    value: initialPage,
    label: `Page ${initialPage + 1}`,
  });

  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const initialFilters = useMemo(() => {
    return _.map(snap.filters, (value, id) => ({ id, value: value ?? '' }));
  }, [snap.filters]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    selectedFlatRows,
    state,
    visibleColumns,
    nextPage,
    pageOptions,
    pageCount,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        pageSize: 5,
        pageIndex: pageSelect.value,
        filters: initialFilters,
      },
      // stateReducer: (newState, action) => {
      //   if (action.type === 'toggleRowSelected') {
      //     newState.selectedRowIds = {
      //       [action.id]: true,
      //     };
      //   }

      //   return newState;
      // },
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination,
    // useRowSelect,
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => [
    //     // Let's make a column for selection
    //     {
    //       id: 'selection',
    //       // The header can use the table's getToggleAllRowsSelectedProps method
    //       // to render a checkbox
    //       Header: ({ getToggleAllRowsSelectedProps }) => (
    //         <div>{/* <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} /> */}</div>
    //       ),
    //       // The cell can use the individual row's getToggleRowSelectedProps method
    //       // to the render a checkbox
    //       Cell: ({ row }) => (
    //         <div>
    //           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} row={row} />
    //         </div>
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // },
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // const firstPageRows = rows.slice(0, 10);
  let pageSelectData = Array.apply(null, Array(pageOptions.length)).map(function () {});
  let numberOfRowsData = [5, 10, 20, 25, 50, 100];

  const navigations = (
    <div className="-pagination">
      <div className="-previous">
        <button
          type="button"
          onClick={() => {
            navigatePage(pageSelect.value - 1);
            handlePageSelect({ value: pageSelect.value - 1, label: 'Page ' + pageSelect.value });
            previousPage();
          }}
          disabled={!canPreviousPage}
          className="-btn"
        >
          Previous
        </button>
      </div>
      <div className="-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="4" sm="6" xs="12">
              <Select
                className="react-select"
                name="pageSelect"
                value={pageSelect}
                onChange={(value) => {
                  gotoPage(value.value);
                  navigatePage(value.value);
                  handlePageSelect(value);
                }}
                options={pageSelectData.map((prop, key) => {
                  return {
                    value: key,
                    label: 'Page ' + (key + 1),
                  };
                })}
                placeholder="Choose Page"
              />
            </Col>
            <Col md="4" sm="6" xs="12">
              <Select
                className="react-select"
                name="numberOfRows"
                value={numberOfRows}
                onChange={(value) => {
                  setPageSize(value.value);
                  setNumberOfRows(value);
                }}
                options={numberOfRowsData.map((prop) => {
                  return {
                    value: prop,
                    label: prop + ' rows',
                  };
                })}
                placeholder="Choose Rows"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="-next">
        <button
          type="button"
          onClick={() => {
            navigatePage(pageSelect.value + 1);
            handlePageSelect({ value: pageSelect.value + 1, label: 'Page ' + (pageSelect.value + 2) });
            nextPage();
          }}
          disabled={!canNextPage}
          className="-btn"
        >
          Next
        </button>
      </div>
    </div>
  );

  const pagFromBase = pageSelect.value * numberOfRows.value;
  const pagFrom = pagFromBase + 1;
  let pagTo = pagFromBase + numberOfRows.value;
  if (pagTo > data.length) pagTo = data.length;

  return (
    <>
      <div className="tw-italic">
        Showing {pagFrom} to {pagTo} of {data.length}
      </div>
      <div className={classnames('ReactTable -striped -highlight primary-pagination', className)}>
        <div className="pagination-top"></div>
        <TableWrapper>
          <RtTable {...getTableProps()} className="rt-table table-sm" compact={compact}>
            <thead className="rt-thead -header">
              {parentColumns && (
                <tr className="rt-tr">
                  {parentColumns.map((col, index) => {
                    return <ParentHeader key={`${col}-${index}`}>{col}</ParentHeader>;
                  })}
                </tr>
              )}
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                  {headerGroup.headers.map((column, key) => {
                    const isLastCol = headerGroup.headers.length - 1 === key;
                    return (
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps({ title: column.Header }))}
                        className={classnames('rt-th', {
                          // '-cursor-pointer': !isLastCol,
                          '-cursor-pointer': true,
                          '-sort-asc': column.isSorted && !column.isSortedDesc,
                          '-sort-desc': column.isSorted && column.isSortedDesc,
                        })}
                      >
                        <div className="rt-resizable-header-content text-center tw-text-[0.7rem] tw-py-1">
                          {column.render('Header')}
                        </div>
                        {/* Render the columns filter UI */}
                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                        {/* <div>{isLastCol ? null : column.canFilter ? column.render('Filter') : null}</div> */}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="rt-tbody tw-text-center">
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    key={`${id}-${i}`}
                    className={classnames('rt-tr', { ' -odd': i % 2 === 0 }, { ' -even': i % 2 === 1 })}
                    style={{ backgroundColor: row.original.__selected ? selectedColor : 'white' }}
                    // ref={elementsRef.current[i]}
                    // onClick={() => onRowSelect(row, elementsRef.current[i], elementsRef.current)}
                    onClick={() => onRowSelect(row, page)}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <RtCell {...cell.getCellProps()} className="rt-td">
                          {cell.render('Cell')}
                        </RtCell>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </RtTable>
        </TableWrapper>
        <div className="pagination-bottom">{navigations}</div>
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== 'number';

export default memo(Table);
